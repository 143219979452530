<template>
    <div>
        <b-row>
            <b-col cols="12" md="4">
                <b-form-group label="Mostrar" label-for="per_page" label-class="font-weight-bold">
                    <v-select id="per_page" 
                        v-model="perPage" 
                        :options="perPageOptions" 
                        :reduce="option => option.value" 
                        :clearable="false"
                        name="label"
                        class="select-size-sm"
                        @input="selectWarehouseOnDetail" />
                </b-form-group>
            </b-col>

            <b-col cols="12" md="8">
                <b-form-group label="Almacén" label-for="new_almacen_Id" label-class="font-weight-bold">
                    <v-select class="select-size-sm" id="new_almacen_Id"
                        v-model="currenWarehouseId"                                                    
                        :options="wareHouses"
                        :reduce="option => option.almacen_Id" label="nombre"
                        placeholder="Selecciona una opción" 
                        :clearable="false"
                        :searchable="false"
                        size="sm"
                        @input="selectWarehouseOnDetail">
                    </v-select>
                </b-form-group>
            </b-col>
            <b-col cols="12" md="5"></b-col>
        </b-row>

        <!-- <b-table :items="kardexes" responsive :fields="columnKardex" 
            class="position-relative b-table-responsive mb-0_ small my-small text-small"
            :sticky-header="true" :no-border-collapse="true" small 
            show-empty 
            empty-text="No se encontraron registros"
            :busy.sync="loading"
            style="max-height: 300px!important;"
            >            
            <template slot="empty">
                <h6 class="text-center mt-1">
                    No se encontraron registros
                </h6>
            </template>
            
            <template #cell(fecha)="data">
                <span class="text-capitalize">{{ formatDate(data.item.fecha) }}</span>
            </template>
        </b-table> -->
        <b-row>
            <b-col cols="12" md="12">
                <div class="position-relative b-table-responsive mb-0 small my-small text-small b-table-sticky-header table-responsive" style="width: 100%!important;" >
                    <table style="max-height: 300px!important; width: 100%!important;"  class="table table-responsive table text-small table-sm">
                        <thead>
                            <tr>
                                <th class="text-nowrap" style="font-size: 8px!important;">Fecha</th>
                                <th class="text-nowrap" style="font-size: 8px!important;">Concepto</th>
                                <th class="text-nowrap" style="font-size: 8px!important;">Folio</th>
                                <th class="text-nowrap" style="font-size: 8px!important;">Descripción</th>
                                <th class="text-nowrap" style="font-size: 8px!important;">Unidades</th>
                                <th class="text-nowrap" style="font-size: 8px!important;">Inv. Final</th>
                            </tr>
                        </thead>
                        <tbody v-if="kardexes.length > 0">
                            <tr v-for="(item, index) in kardexes" :key="index">
                                <td style="font-size: 10px!important;" class="text-nowrap text-capitalize">{{ formatDate(item.fecha) }}</td>
                                <td style="font-size: 10px!important;" class="text-nowrap">{{ item.concepto.trim() }}</td>
                                <td style="font-size: 10px!important;" class="text-nowrap">{{ item.folio }}</td>
                                <td style="font-size: 10px!important;" class="text-wrap text-truncate" v-b-tooltip.hover.right="item.descripcion">{{ item.descripcion }}</td>
                                <td style="font-size: 10px!important;" class="text-nowrap">{{ item.unidades }}</td>
                                <td style="font-size: 10px!important;" class="text-nowrap">{{ item.inventario }}</td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="7">
                                    <h6 class="text-center mt-1"> No se encontraron registros </h6>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </b-col>
        </b-row>

    </div>
</template>

<script>
import { BButton, BModal, VBModal, BForm, BFormInput, BInputGroup, BFormGroup, BFormCheckbox, BFormFile, BInputGroupPrepend, BFormInvalidFeedback,
    BCardText, BCard, BCardHeader, BCol, BRow, BImg, BMedia, BAvatar, BOverlay, BSpinner, BFormRadioGroup, BFormDatepicker, BTable, BTableLite, BTooltip,
    VBTooltip, VBToggle, BBreadcrumb, BPagination, BCollapse, BLink, BTabs,  BTab, BNavItem, BAlert, BIcon,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { ref, computed, onMounted, watch, reactive, defineEmits, nextTick } from "@vue/composition-api";
import store from '@/store';
import * as helper from '@/libs/helpers'

import KardexProvider from "@/providers/Kardex"
const KardexResource = new KardexProvider()

import WareHousesProvider from "@/providers/WareHouses"
const WareHousesResource = new WareHousesProvider()

export default {
    name: 'Kardex',
    components: {
        BButton,
        BModal,
        BForm,
        BFormInput,
        BInputGroup,
        BFormGroup,
        BFormCheckbox,
        BFormFile,
        BInputGroupPrepend,
        BFormInvalidFeedback,
        BCardText,
        BCard,
        BCardHeader,
        BCol,
        BRow,
        BImg,
        BMedia,
        BAvatar,
        BOverlay,
        BSpinner,
        BFormRadioGroup,
        BFormDatepicker,
        BTable,
        BTableLite,
        BTooltip,
        VBTooltip,
        BBreadcrumb,
        BPagination,
        BCollapse,
        BLink,
        BNavItem,
        BTabs, 
        BTab,
        BAlert,
        BIcon,

        //
        vSelect,
       
    },
    directives: {
        "b-modal": VBModal,
        'b-tooltip': VBTooltip,
        'b-toggle': VBToggle,
        Ripple,
    },
    props: {
        active: {
            type: Boolean,
            required: true,
            default: () => false,
        },
        article: {
            type: Object,
            required: true,
            default: () => {}
        },
        connection: {
            type: Number,
            required: true,
            default: () => 0,
        },
        branch: {
            type: Number,
            required: false,
            default: () => 0,
        },
        // warehouse: {
        //     type: Number,
        //     required: false,
        //     default: () => 0,
        // },
        // warehouses: {
        //     type: Array,
        //     required: false,
        //     default: () => []
        // }
    },
    setup(props, { emit }) {
        
        const loading = ref(false)
        const kardexes = ref([])
        const perPage = ref(30)
        const perPageOptions = ref([
            { value: 0, label: 'Todas' },
            { value: 30, label: 30 },
            { value: 50, label: 50 },
            { value: 100, label: 100 },
            { value: 300, label: 300 },
            { value: 500, label: 500 },
            { value: 1000, label: 1000 },
        ]);
        const currenWarehouseId = ref(null)
        const wareHouses = ref([])

       
        //Table last purchases
        const columnKardex = [
            {
                key: 'fecha',
                label: 'Fecha',
                tdClass: 'text-nowrap text-small',
                tdStyle: 'font-size: 4px!important',
                thStyle: 'font-size: 7px!important'
            },
            {
                key: 'concepto',
                label: 'Concepto',
                tdClass: 'text-nowrap text-small',
                tdStyle: 'font-size: 4px!important',
                thStyle: 'font-size: 7px!important'
            },
            {
                key: 'folio',
                label: 'Folio',
                tdClass: 'text-nowrap text-small',
                tdStyle: 'font-size: 4px!important',
                thStyle: 'font-size: 7px!important'
            },
            {
                key: 'descripcion',
                label: 'Descripción',
                tdClass: 'text-nowrap text-small',
                tdStyle: 'font-size: 4px!important',
                thStyle: 'font-size: 7px!important'
            },
            {
                key: 'unidades',
                label: 'Unidades',
                tdClass: 'text-nowrap text-small',
                tdStyle: 'font-size: 4px!important',
                thStyle: 'font-size: 7px!important'
            },
            {
                key: 'inventario',
                label: 'InvFinal',
                tdClass: 'text-nowrap text-small',
                tdStyle: 'font-size: 4px!important',
                thStyle: 'font-size: 7px!important'
            },
        ]

        const getWareHouses = async () => {
            wareHouses.value = []
            loading.value = true
            const { data } = await WareHousesResource.getByCompanyId(props.connection)
            loading.value = false
            wareHouses.value = data.data

            nextTick(() => {
                if (data.data.length > 0) {
                    currenWarehouseId.value = data.data[0].almacen_Id
                    getKardexes()
                }
            })

            

            console.log(wareHouses.value)
        }

        getWareHouses()


        const getKardexes = async () => {
            kardexes.value = []
            try {
                loading.value = true
                const { data } = await KardexResource.getKardexByConnection(props.connection, props.article.articulo_Id, currenWarehouseId.value, props.branch, perPage.value)
                loading.value = false
                if (data.isSuccesful) {
                    kardexes.value = data.data
                } else {
                    helper.danger(data.message)
                }
            }catch(e) {
                loading.value = false
                helper.handleResponseErrors(e)
            }
        }

        

        const selectWarehouseOnDetail = async (event) => {            
            await getKardexes()
            // kardexes.value = []
            // try {
            //     loading.value = true
            //     const { data } = await KardexResource.getKardexByConnection(props.connection, props.article.articulo_Id, currenWarehouseId.value, props.branch, perPage.value)
            //     loading.value = false
            //     if (data.isSuccesful) {
            //         kardexes.value = data.data
            //     } else {
            //         helper.danger(data.message)
            //     }
            // }catch(e) {
            //     loading.value = false
            //     helper.handleResponseErrors(e)
            // }    
        }        

        return {
            loading,
            columnKardex,
            perPage,
            perPageOptions,
            kardexes,
            currenWarehouseId,
            wareHouses,

            //
            getKardexes,
            selectWarehouseOnDetail,
        }

    },

}
</script>

<style>
.text-truncate {
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis; 
    max-width: 150px;
}
</style>